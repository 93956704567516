import React from 'react';

export default function Awards() {
    return (
        <div className="page awards-page">
            <div className="page-content coming-soon">
                <h2>Awards</h2>
                <p>Coming soon...</p>
            </div>
        </div>
    );
}
