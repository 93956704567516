import maternity1 from '../img/gallery/maternity/maternity-1.jpg';
import maternity2 from '../img/gallery/maternity/maternity-2.jpg';
import maternity3 from '../img/gallery/maternity/maternity-3.jpg';
import maternity4 from '../img/gallery/maternity/maternity-4.jpg';
import maternity5 from '../img/gallery/maternity/maternity-5.jpg';
import maternity6 from '../img/gallery/maternity/maternity-6.jpg';
import maternity7 from '../img/gallery/maternity/maternity-7.jpg';
import maternity8 from '../img/gallery/maternity/maternity-8.jpg';
import maternity9 from '../img/gallery/maternity/maternity-9.jpg';
import maternity10 from '../img/gallery/maternity/maternity-10.jpg';
import maternity11 from '../img/gallery/maternity/maternity-11.jpg';
import maternity12 from '../img/gallery/maternity/maternity-12.jpg';
import maternity13 from '../img/gallery/maternity/maternity-13.jpg';
import maternity14 from '../img/gallery/maternity/maternity-14.jpg';
import maternity15 from '../img/gallery/maternity/maternity-15.jpg';
import maternity16 from '../img/gallery/maternity/maternity-16.jpg';

import newborn1 from '../img/gallery/newborn/newborn-1.jpg';
import newborn2 from '../img/gallery/newborn/newborn-2.jpg';
import newborn3 from '../img/gallery/newborn/newborn-3.jpg';

import sittersession1 from '../img/gallery/sittersession/sittersession1.jpg';
import sittersession2 from '../img/gallery/sittersession/sittersession2.jpg';
import sittersession3 from '../img/gallery/sittersession/sittersession3.jpg';

import family1 from '../img/gallery/family/family-1.jpg';
import family2 from '../img/gallery/family/family-2.jpg';
import family3 from '../img/gallery/family/family-3.jpg';

import cake1 from '../img/gallery/cakesmash/cake-smash-1.jpg';
import cake2 from '../img/gallery/cakesmash/cake-smash-2.jpg';
import cake3 from '../img/gallery/cakesmash/cake-smash-3.jpg';

import engagement1 from '../img/gallery/engagement/engagement1.jpg';
import engagement2 from '../img/gallery/engagement/engagement2.jpg';
import engagement3 from '../img/gallery/engagement/engagement3.jpg';

export const maternityPhotos = [
    {
        src: maternity1,
        width: 750,
        height: 500,
        alt: "Maternity 1",
    },
    {
        src: maternity2,
        width: 750,
        height: 500,
        alt: "Maternity 2",
    },
    {
        src: maternity3,
        width: 500,
        height: 700,
        alt: "Maternity 3",
    },
    {
        src: maternity4,
        width: 750,
        height: 500,
        alt: "Maternity 4",
    },
    {
        src: maternity5,
        width: 500,
        height: 700,
        alt: "Maternity 5",
    },
    {
        src: maternity6,
        width: 500,
        height: 700,
        alt: "Maternity 6",
    },
    {
        src: maternity7,
        width: 500,
        height: 700,
        alt: "Maternity 7",
    },
    {
        src: maternity8,
        width: 500,
        height: 700,
        alt: "Maternity 8",
    },
    {
        src: maternity9,
        width: 500,
        height: 700,
        alt: "Maternity 9",
    },
    {
        src: maternity10,
        width: 500,
        height: 700,
        alt: "Maternity 10",
    },
    {
        src: maternity11,
        width: 500,
        height: 700,
        alt: "Maternity 11",
    },
    {
        src: maternity12,
        width: 500,
        height: 700,
        alt: "Maternity 12",
    },
    {
        src: maternity13,
        width: 500,
        height: 700,
        alt: "Maternity 13",
    },
    {
        src: maternity14,
        width: 750,
        height: 500,
        alt: "Maternity 14",
    },
    {
        src: maternity15,
        width: 750,
        height: 500,
        alt: "Maternity 15",
    },
    {
        src: maternity16,
        width: 750,
        height: 500,
        alt: "Maternity 16",
    },
];

export const newbornPhotos = [
    {
        src: newborn1,
        width: 500,
        height: 750,
        alt: "Maternity 1",
    },
    {
        src: newborn2,
        width: 750,
        height: 500,
        alt: "Maternity 2",
    },
    {
        src: newborn3,
        width: 750,
        height: 500,
        alt: "Maternity 3",
    },
];

export const sitterSessionPhotos = [
    {
        src: sittersession1,
        width: 709,
        height: 500,
        alt: "Maternity 1",
    },
    {
        src: sittersession2,
        width: 750,
        height: 500,
        alt: "Maternity 2",
    },
    {
        src: sittersession3,
        width: 750,
        height: 500,
        alt: "Maternity 3",
    },
];

export const familyPhotos = [
    {
        src: family1,
        width: 500,
        height: 750,
        alt: "Maternity 1",
    },
    {
        src: family2,
        width: 500,
        height: 750,
        alt: "Maternity 2",
    },
    {
        src: family3,
        width: 500,
        height: 500,
        alt: "Maternity 3",
    },
];

export const cakeSmashPhotos = [
    {
        src: cake1,
        width: 500,
        height: 500,
        alt: "Maternity 1",
    },
    {
        src: cake2,
        width: 500,
        height: 500,
        alt: "Maternity 2",
    },
    {
        src: cake3,
        width: 750,
        height: 500,
        alt: "Maternity 3",
    },
];

export const engagementPhotos = [
    {
        src: engagement1,
        width: 500,
        height: 750,
        alt: "Maternity 1",
    },
    {
        src: engagement2,
        width: 500,
        height: 684,
        alt: "Maternity 2",
    },
    {
        src: engagement3,
        width: 500,
        height: 750,
        alt: "Maternity 3",
    },
];

