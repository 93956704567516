export const faq = [
    // Maternity shoots
    {
        h2: "Maternity shoots",
        isActive: true,
        h3: "When should I book my maternity shoot?",
        p: "You can book your session anytime after your 12 week scan and I usually advise scheduling a date for when you are between 30 & 34 weeks."
    },
    {
        isActive: true,
        h3: "What should I wear?",
        p: "Your maternity shoot styling is completely your choice. You can have it as formal or informal as you like or even have a mix of both. You can wear your own outfit or you are welcome to use any of my maternity dresses and accessories."
    },

    // Newborn shoots
    {
        h2: "Newborn shoots",
        isActive: true,
        h3: "When do I book my newborn shoot?",
        p: "You can book your newborn shoot after your 12 week scan."
    },
    {
        isActive: true,
        h3: "How do I know what date to book for my newborn shoot?",
        p: "We can schedule your newborn shoot date for roughly 5-12 days after your due date, however, I will be flexible as you never know when baby will decide to make his or her appearance into this world."
    },
    {
        isActive: true,
        h3: "Can I bring siblings to the session?",
        p: "Of course, they are part of the family too. These sessions are long, so bring toys or an iPad to keep them busy, or if they are toddlers, it might be easier for a family member to bring them for the last 30mins of the session."
    },
    {
        isActive: true,
        h3: "What do I need to bring to bring to my newborn shoot?",
        p: "A change of clothes in case baby decides to share their milk with you, a dummy and enough formula for a few feeds if they are not breast fed."
    },
    {
        isActive: true,
        h3: "What if baby is unsettled and crying?",
        p: "Babies cry, it's their only way to communicate and let us know that there is something wrong. Generally they are either hungry, tired and have stomach cramps. I never rush my sessions and follow babies lead, if he or she does not want to settle in a particular pose, I will move onto the next one. I never promise a particular pose but will do my best to get the shots you love, however, baby is boss during these sessions."
    },

    // Family sessions,
    {
        h2: "Family sessions",
        isActive: true,
        h3: "What must we wear?",
        p: "Whatever you're comfortable in, however, I do suggest wearing similar colours / tones and preferably unbranded as todays brands may not be that popular in a few years time."
    },

    //Cake Smash sessions
    {
        h2: "Cake Smash sessions",
        isActive: true,
        h3: "Do you provide the cake?",
        p: "I can provide a small rosette buttercream cake for your little one to smash at an extra cost of £20 provided they have no allergies."
    },
    {
        isActive: true,
        h3: "What themes do you offer and do you provide the decor?",
        p: "I offer a variety of themes which can be seen on my pages but I am always eager to change things up and try new themes. I provide a styled set in my home studio which generally includes the backdrop, balloon arch, giant letters 'ONE' and any other props I might have to suit your chosen theme."
    },
    {
        isActive: true,
        h3: "What should my little one wear?",
        p: "You can dress them however you want, a themed outfit is always cute, however a simple vest is just as cute. I usually take a few clean formal shots before the cake comes out, so you are welcome to bring two outfits in if you like."
    },
];
