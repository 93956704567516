import React from 'react';
import { NavLink } from 'react-router-dom';

import { IoIosPhotos as AlbumIcon } from "react-icons/io";

export default function CategoryBox({ category, flipped }) {
    return (
        <div className='category-container'>
            <div className={flipped ? 'category-box flipped' : 'category-box'}>

                <div className='category-photo-box'>
                    <div className={category.name}>
                        <NavLink
                            to={'/gallery' + category.album}
                        > <AlbumIcon className='icon' /> </NavLink>
                    </div>
                </div>

                <div className='category-description-box'>
                    <h3>
                        {category.name}
                    </h3>
                    <p className='grad-text'>{category.p}</p>
                </div>

            </div>
        </div>
    );
}
