import React from 'react';
import { NavLink } from 'react-router-dom';

export default function SecondaryNav() {
    return (
        <nav className='secondary-nav'>
            <ul>
                <li>
                    <NavLink
                        to={"/faq"}
                    > Faq </NavLink>
                </li>
                <li>
                    <NavLink
                        to={"/contact"}
                    > Contact Me </NavLink>
                </li>
                <li>
                    <NavLink
                        to={"/privacy-policy"}
                    > Privacy Policy </NavLink>
                </li>
            </ul>
        </nav>
    )
}
